import { useEffect, useState } from "react";
import { Input } from "../../components/Input";
import { CustomCheckbox } from "../../components/Checkbox";
import { Select } from "../../components/Select";
import { HiOutlineCheck } from "react-icons/hi2";
import { Button } from "../../components/Button";
import { PrincipalButton } from "../../components/PrincipalButton";
import { HiLockClosed } from "react-icons/hi2";
import api from "../../api/api";
import { toast } from "react-toastify";
import { knowAcces } from "../../utils/utils";

export const PayrollClienti = ({
  cliente,
  handleChange,
  handleSave,
  handleChangeSelect,
}) => {
  const [ccnl, setCcnl] = useState([]);
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [payroll, setPayroll] = useState([]);
  /* useEffect(() => {
    (() => {
      console.log(cliente);
    })();
  }, []); */

  const loadCCnl = async () => {
    try {
      const ccnl = await api.get(`/ccnl/${cliente.codice_univoco}`);
      setCcnl(ccnl.data);
    } catch {
      toast.error("Errore durante il caricamento");
    }
  };

  const loadTeamLeader = async () => {
    try {
      const tl = await api.get(`/utenti/team_leaders`);
      setTeamLeaders(tl.data);
    } catch {
      toast.error("Errore durante il caricamento");
    }
  };
  const loadPayroll = async () => {
    try {
      const tl = await api.get(`/utenti/payroll`);
      let data = tl.data.filter(
        (el) => el.codice_zucchetti !== cliente.referente_payroll
      );
      setPayroll(data);
    } catch {
      toast.error("Errore durante il caricamento");
    }
  };

  useEffect(() => {
    loadCCnl();
    loadTeamLeader();
    loadPayroll();
  }, []);

  const handleSavePayroll = async () => {
    try {
      if (ccnl.length > 0) {
        let savePayroll = await api.put("/ccnl", { ccnl });
      }

      handleSave();
    } catch {
      toast.error("Errore durante il salvataggio ");
    }
  };

  return (
    <>
      <PrincipalButton
        button={
          <Button
            className="principal-button z-[110]"
            text="Salva"
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleSavePayroll();
            }}
          />
        }
      />
      <div className="grid grid-cols-2 gap-2 w-full el-container">
        <div className="col-span-2 md:col-span-1">
          <Input
            type="text"
            label={"Gruppo Aziende"}
            name="gruppo_cliente"
            value={cliente.gruppo_cliente}
            disabled={true}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Select
            type="text"
            label={"Team Leader"}
            name="team_leader"
            // onChange={(e) => handleChangeSelect(e,"team_leader")}
            value={cliente.responsabile}
            placeholder="Seleziona..."
            options={teamLeaders}
            disabled={true}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            type="text"
            label={"referente Paghe 1"}
            name="referente_attivo_payroll"
            value={cliente.referente_attivo_payroll}
            disabled={true}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Select
            type="text"
            label={"Referente paghe 2 "}
            name="referente_payroll_2"
            onChange={(e) => handleChangeSelect(e, "referente_payroll_2")}
            value={cliente.referente_payroll_2}
            placeholder="Seleziona..."
            options={payroll}
            disabled={!knowAcces(["admin", "team_leader", "paghe"])}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            type="text"
            label={"CDL di riferimento"}
            name="cdl_di_riferimento"
            value={cliente.cdl_di_riferimento}
            disabled={true}
          />
        </div>

        <div className="col-span-2 md:col-span-1">
          <Input
            type="number"
            label={"n Medio Cedolini Trimestrale"}
            name="media_cedolini"
            onChange={(e) => handleChange(e)}
            value={cliente.media_cedolini}
            disabled={true}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            type="number"
            label={"scadenza Consegna Paghe"}
            name="scadenzaConsegnaPaghe"
            value={Math.trunc(cliente.scadenza_consegna_paghe)}
            disabled={true}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            type="text"
            label={"metodo caricamento presenze"}
            name="caricamento_presenze"
            value={cliente.caricamento_presenze}
            disabled={true}
          />
        </div>

        <div className="col-span-2 md:col-span-1">
          <Select
            type="text"
            label={"autorizzazione Stampa"}
            name="autorizzazioneStampa"
            //onChange={(e) => handleChange(e)}
            value={cliente.autorizzazione_stampa_lul}
            placeholder="SELEZIONA..."
            options={[
              { id: "Aziendale", name: "AZIENDALE" },
              { id: "Unitaria", name: "UNITARIA" },
            ]}
            disabled={true}
          />
        </div>

        <div className="col-span-2 md:col-span-1">
          <CustomCheckbox
            label={"pubblicazione Documenti ai dipendenti "}
            name="pubblicazione_doc_dipendente"
            //checked={cliente.pubblicazione_doc_dipendente}
            //onClick={(e) => filter(e, "assegnati")}
            value={cliente.pubblicazione_doc_dipendenti === "S"}
            disabled={true}
          />
        </div>

        <div className="col-span-2 md:col-span-1">
          <CustomCheckbox
            label="PRESENZE DIFFERITE"
            name="presenze_differite"
            // checked={true}
            //onClick={(e) => filter(e, "assegnati")}
            value={cliente.presenze_differite === "S"}
            disabled={true}
          />
        </div>

        <div className="col-span-2 grid grid-cols-3 gap-2 w-full ">
          {ccnl.map((el, index) => (
            <>
              <div className="col-span-3 md:col-span-1">
                <Input
                  type="text"
                  label={"ccnl " + (index + 1)}
                  name={"ccnl " + (index + 1)}
                  value={
                    el.codice_contratto +
                    " - " +
                    el?.descrizione_ccnl +
                    " - " +
                    el?.codice_cnel
                  }
                  disabled={true}
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Input
                  type="text"
                  label={"settore Ccnl " + (index + 1)}
                  name={"settoreCcnl" + (index + 1)}
                  value={
                    el.codice_settore && el.descrizione_settore_ccnl
                      ? el.codice_settore + " - " + el.descrizione_settore_ccnl
                      : ""
                  }
                  disabled={true}
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Input
                  type="text"
                  label={"raccordo Ccnl " + (index + 1) + " IPSOA"}
                  name="raccordoCcnl1"
                  value={el.ipsoa}
                  disabled={true}
                />
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};
