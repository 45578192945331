import React,{useEffect, useState} from "react";
import api from "../../api/api";
import { Select } from "../../components/Select";
import { Input } from "../../components/Input";
import moment from "moment";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, LineChart, AreaChart, Area, Cell } from 'recharts';





export const Cedolini = ({ screenSize, f24,dataF24}) => {
const [utenti,setUtenti] = useState([]);
const [utenteSel,setUtenteSel] = useState(null);
const [dataA,setDataA] = useState(moment(new Date()).format("YYYY-MM"))
const [dataDa,setDataDa] = useState(moment(new Date()).subtract(6, 'months').format("YYYY-MM"))
const [parcellazione,setParcellazione] = useState([]);
const [parcellazioneAttive,setParcellazioneAttive] = useState([]);
const [aziende,setAziende] = useState([]);
const [aziendaSel,setAziendaSel] = useState(null);

const [barSel,setBarSel] = useState(null)
const [meseSel,setMeseSel] = useState(null)

    useEffect(() => {}, [screenSize]);

    const loadUtenti = async() => 
    {
        const utenti = await api.get("/utenti");
    setUtenti(utenti.data.filter(el => el.permission !=1 && el.codice_zucchetti))
      }
  

useEffect(() => {
  loadUtenti()

},[]);

useEffect(() => {

if( utenteSel != 0 && utenteSel && dataA && dataDa ){

  loadParcellazione(utenteSel,dataDa,dataA)
  setBarSel(null)
}else{
  setAziendaSel(null)
  setBarSel(null)
}

}, [utenteSel,dataA,dataDa,aziendaSel]);



const loadParcellazione = async(referente,da,a) => {
  const parc = await api.get(`/parcellazione/statistiche/referente`,{params:{referente:referente,da:da,a:a}});
  setParcellazione(parc.data.data)
  setParcellazioneAttive(parc.data.data)
  setAziende(parc.data.societa)
}

const handleBarClick = (el,index) => {
  let meseSelTemp = parcellazione.filter(row => row.mese === el.mese)
  let arr =[];
  Object.keys(meseSelTemp[0]).map(el => 
    {
    if(el !=="mese" &&el!=="totale_mese" && meseSelTemp[0][el]!== 0){


      arr.push({name:el,value:meseSelTemp[0][el]})
    }
    }
    )




  setMeseSel(arr)
  setBarSel(index)



}

const handleChangeAzienda = async(e) => {
setAziendaSel(e.target.value)


}



return (


    <div className="el-container h-fit ">


    <div className="grid grid-cols-4 gap-2 h-full" >

    <div className="col-span-2 md:col-span-1">
        <Input
             type="month" 
              label={"Da"}
              name="da"
              value={dataDa}
              onChange={async (e) =>{
                await setDataDa(e.target.value);
                //loadParcellazione(moment(e.target.value).format("YYYY-MM"),moment(dataA).format("YYYY-MM"))
            }}
              max={moment(dataA).format("YYYY-MM")}
      
            />
    </div>
    <div className="col-span-2 md:col-span-1">
             <Input
              type="month" 
              label={"A"}
              name="a"
              onChange={async (e) =>{await setDataA(e.target.value);
                //loadParcellazione(moment(dataDa).format("YYYY-MM"),moment(e.target.value).format("YYYY-MM"))
            }}
              min={moment(dataDa).format("YYYY-MM")}
             value={dataA}
      
            />
      </div>
      <div className="col-span-2 md:col-span-1">
          <Select
            type="text"
            label={"Referente"}
            name="tipologia_servizio_altro"
           onChange={(e) => setUtenteSel(e.target.value)}
            //value={cliente.tipologia_servizio_altro}
            placeholder="Seleziona..."
            options={utenti.map(el => ( {id:el.codice_zucchetti,name:el.nome}))}
            disabled={false}
          />
        </div>
        {utenteSel && utenteSel !=0?
      <div className="col-span-2 md:col-span-1">
          <Select

            type="text"
            label={"Azienda"}
            name="aziende"
           onChange={handleChangeAzienda}
            //value={cliente.tipologia_servizio_altro}
            placeholder="Seleziona..."
            options={aziende.map((el,index) => ( {id:index+1,name:el}))}
            disabled={false}
          />
        </div>:""}
      <div className="col-span-4 el-container h-80">
      
       
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          width={500}
          height={300}
          data={parcellazioneAttive}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="mese" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey={aziendaSel && aziendaSel != 0?aziende[aziendaSel-1]:"totale_mese"} fill="#2265B1" activeBar={<Rectangle fill="#8dc4eb" stroke="#8dc4eb" />} onClick={handleBarClick}>
          {parcellazioneAttive.map((entry, index) => (
                <Cell cursor="pointer" fill={index === barSel ? '#8dc4eb' : '#2265B1'} key={`cell-${index}`} />
              ))}
              
            </Bar>
          
        </BarChart>
      </ResponsiveContainer>
      </div>
{meseSel?
      <div className="col-span-4 el-container h-80">
      
       
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          width={500}
          height={300}
          data={meseSel}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={false}/>
          <YAxis />
          <Tooltip />
      
       
    <Bar dataKey={"value"} key={"value"} fill="#2265B1" />
   
      
   
          
        </BarChart>
      </ResponsiveContainer>
      </div>
:""}

    </div>




    </div>
)

}

