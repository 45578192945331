import { AES } from "crypto-js";
import CryptoJS from "crypto-js";

export const handleCalcolo = (
  prestazione,
  importo_prestazione,
  metodo_fatturazione,
  cedolini_elaborati
) => {
  let importo = 0;

  switch (metodo_fatturazione) {
    case 1: //FORFAIT prezzo diretto. Listino prezzo per clienti
      importo = importo_prestazione;
      break;

    case 3: //LISTINO prezzo diretto. Listino prezzo per clienti
      importo = parseInt(cedolini_elaborati) * importo_prestazione;

      break;
  }

  return importo;
};

export const knowAcces = (options) => {
  //option sarà un array con i dettagli di chi può entrare

  let auth = AES.decrypt(
    sessionStorage.getItem("cpt_auth"),
    "FPfT5JvxQ2SN"
  ).toString(CryptoJS.enc.Utf8); //le auth che ci sono

  let res = JSON.parse(auth);

  for (let i = 0; i < options.length; i++) {
    console.log(res);
    if (res[options[i]] == 1) {
      return true;
    }
  }

  return false;
};
