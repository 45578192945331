import { useEffect, useState } from "react";
import { Input } from "../../components/Input";
import { CustomCheckbox } from "../../components/Checkbox";
import { Select } from "../../components/Select";
import { PrincipalButton } from "../../components/PrincipalButton";
import { Button } from "../../components/Button";
import { HiMiniAdjustmentsVertical, HiOutlineCheck, HiOutlineTrash, HiPlus, HiPlusCircle } from "react-icons/hi2";
import LinearProgress from "@mui/material/LinearProgress";
import { CustomFooterPersonalized } from "../../components/DataGrid/CustomFooterPersonalized";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import Modal from "../../components/Modal";
import api from "../../api/api";
import { toast } from "react-toastify";
import { MdEdit, MdCheckCircle } from "react-icons/md";
import { HiClipboardCopy } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { TabPanel } from "../../components/TabPanel";

import { TbCircleLetterZ, TbCircleLetterB, TbCircleLetterI } from "react-icons/tb";




export const FatturazioneClienti = ({ cliente, handleChange, handleSave, handleChangeSelect, handleChangeCheckbox }) => {
  const dati_default_zucchetti = {

    codice_univoco_azienda: cliente.codice_univoco,
    tipologia_cliente: null,
    importo_forfait: "",
    max_cedolini: "",
    costo_extra: ""

  };
  const dati_default_bpoint = {

    codice_univoco_azienda: cliente.codice_univoco,
    tipologia_cliente: null,
    importo_forfait: "",
    max_cedolini: "",
    costo_extra: ""

  };
  const dati_default_iipay = {

    codice_univoco_azienda: cliente.codice_univoco,
    tipologia_cliente: null,
    importo_forfait: "",
    max_cedolini: "",
    costo_extra: ""

  };


  const [tipologiaFatturazione, setTipologiaFatturazione] = useState([]);
  const [prestazioniDisponibili, setPrestazioniDisponibili] = useState([])
  const [listino, setListino] = useState([])

  const [prestazioneSel, setPrestazioneSel] = useState(null);
  const [dati, setDati] = useState(dati_default_zucchetti)




  const loadTipologie = async () => {
    try {
      const tip = await api.get(`/tipologia_fatturazione`);
      setTipologiaFatturazione(tip.data)

    } catch {
      toast.error("Errore durante il caricamento")
    }

  }


  const loadPrestazioniTipologia = async () => {
    try {
      const risp = await api.get(`/prestazione_azienda/${cliente.tipologia_fatturazione}`);
      setPrestazioniDisponibili(risp.data)

    } catch {
      toast.error("Errore durante il caricamento")
    }

  }

  const loadListino = async () => {
    try {
      const risp = await api.get(`/prestazione_azienda/listino/${cliente.codice_univoco}`);
      setListino(risp.data)

    } catch {
      toast.error("Errore durante il caricamento")
    }

  }

  const loadDatiParcellazioneZucchetti = async () => {
    try {
      const risp = await api.get(`/dati_parcellazione_zucchetti/${cliente.codice_univoco}`);
      if (risp.data.length > 0) {
        setDati(risp.data[0])
      } else {
        setDati(dati_default_zucchetti)
      }


    } catch {
      toast.error("Errore durante il caricamento")
    }


  }



  const loadDatiParcellazioneIIpay = async () => {
    try {
      const risp = await api.get(`/dati_parcellazione_iipay/${cliente.codice_univoco}`);
      if (risp.data.length > 0) {
        setDati(risp.data[0])

      } else {
        setDati(dati_default_iipay)
      }


    } catch {
      toast.error("Errore durante il caricamento")
    }


  }

  const loadDatiParcellazioneBpoint = async () => {
    try {
      const risp = await api.get(`/dati_parcellazione_bpoint/${cliente.codice_univoco}`);
      if (risp.data.length > 0) {
        setDati(risp.data[0])

      } else {
        setDati(dati_default_bpoint)
      }


    } catch {
      toast.error("Errore durante il caricamento")
    }


  }



  const columns = [
    {
      field: 'Azioni',
      headerName: 'Azioni',

      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <div className='hidden md:flex flex-nowrap gap-2'>
              <Button
                className='edit-button '

                type='button'
                icon={<MdEdit className='button-icon' />}
                onClick={async () => {
                  await setPrestazioneSel(params.row);

                  document.getElementById("editPrestazione").click();
                }}
              />


              <Button
                className='delete-button'
                type='button'
                icon={<HiOutlineTrash className='button-icon' />}
                onClick={async () => {
                  await setPrestazioneSel(params.row);

                  document.getElementById("deletePrestazione").click();
                }}
              />
            </div>
          </>)
      }
    },
    {
      field: "codice",
      headerName: "Codice",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "descrizione",
      headerName: "Descrizione",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "importo",
      headerName: "Importo",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];







  const CustomToolbarContattiEmail = () => {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start ">
            <CopiaDaCliente cliente={cliente} />
            <ModalPrestazione loadListino={loadListino} tipologia={"modifica"} cliente={cliente.codice_univoco} dettaglio={{ referente: "", email_referente: "", tipologia: "" }} prestazioniDisponibili={prestazioniDisponibili} prestazione={prestazioneSel} />
            <ModalPrestazione loadListino={loadListino} tipologia={"inserimento"} cliente={cliente.codice_univoco} dettaglio={{ referente: "", email_referente: "", tipologia: "" }} prestazioniDisponibili={prestazioniDisponibili} listino={listino} />
          </div>
        </div>
      </GridToolbarContainer>
    );
  }


  useEffect(() => {

    loadTipologie();
    loadPrestazioniTipologia();
    loadListino();
    //loadDatiParcellazione();
    if (cliente?.tipologia_fatturazione == "B") {
      loadDatiParcellazioneBpoint();
    } else if (cliente?.tipologia_fatturazione == "Z") {
      loadDatiParcellazioneZucchetti();
    } else if (cliente?.tipologia_fatturazione == "I") {
      loadDatiParcellazioneIIpay();
    }

  }, []);




  return (<>
    <PrincipalButton
      button={
        <Button
          className="principal-button z-[110]"
          text="Salva"
          type="button"
          icon={<HiOutlineCheck className="button-icon" />}
          onClick={async () => {
            handleSave()
          }}
        />
      }
    />

    <div className="grid grid-cols-4 gap-2 w-full el-container ">
      <div className="col-span-4 md:col-span-2">
        <Input
          type="text"
          label={"Codice Parcellazione"}
          name="codice_parcellazione"
          onChange={(e) => handleChange(e)}
          value={cliente.codice_parcellazione}
          disabled={false}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Select
          label={"Tipologia Fatturazione"}
          placeholder="Seleziona..."
          options={tipologiaFatturazione}
          value={cliente.tipologia_fatturazione}
          onChange={(e) => handleChangeSelect(e, "tipologia_fatturazione")}
          disabled={true}
        />
      </div>
      {/*
      <div className="col-span-2 md:col-span-1">

        <CustomCheckbox
          label="Fondi Assicurazione sanitaria"
          name="fondi_assicurazione_sanitaria"
          checked={cliente.fondi_assicurazione_sanitaria}
          onClick={(e) => handleChangeCheckbox(e, "fondi_assicurazione_sanitaria")}
          //onClick={(e) => filter(e, "assegnati")}
          value={cliente.fondi_assicurazione_sanitaria}
          disabled={false}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <CustomCheckbox
          label="Cassa Edile"
          name="cassa_edile"
          checked={cliente.cassa_edile}
          onClick={(e) => handleChangeCheckbox(e, "cassa_edile")}
          //onClick={(e) => filter(e, "assegnati")}
          value={cliente.cassa_edile}
          disabled={false}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <CustomCheckbox
          label="Pubblicazione documentale"
          name="pubblicazione_documentale"
          checked={cliente.pubblicazione_documentale}
          onClick={(e) => handleChangeCheckbox(e, "pubblicazione_documentale")}
          //onClick={(e) => filter(e, "assegnati")}
          value={cliente.pubblicazione_documentale}
          disabled={false}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <CustomCheckbox
          label="Cliente Alfieri"
          name="alfieri"
          checked={cliente.alfieri}
          onClick={(e) => handleChangeCheckbox(e, "alfieri")}
          //onClick={(e) => filter(e, "assegnati")}
          value={cliente.alfieri}
          disabled={false}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <CustomCheckbox
          label="Presenze manuali"
          name="presenze_manuali"
          checked={cliente.presenze_manuali}
          onClick={(e) => handleChangeCheckbox(e, "presenze_manuali")}
          //onClick={(e) => filter(e, "assegnati")}
          value={cliente.presenze_manuali}
          disabled={false}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <CustomCheckbox
          label="Presenze hr portal"
          name="presenze_hr_portal"
          checked={cliente.presenze_hr_portal}
          onClick={(e) => handleChangeCheckbox(e, "presenze_hr_portal")}
          //onClick={(e) => filter(e, "assegnati")}
          value={cliente.presenze_hr_portal}
          disabled={false}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <CustomCheckbox
          label="Pubblicazione documentale dipendenti"
          name="pubblicazione_documentale_dipendenti"
          checked={cliente.pubblicazione_documentale_dipendenti}
          onClick={(e) => handleChangeCheckbox(e, "pubblicazione_documentale_dipendenti")}
          //onClick={(e) => filter(e, "assegnati")}
          value={cliente.pubblicazione_documentale_dipendenti}
          disabled={false}
        />

      </div>
    */}
      <div className="col-span-4">

        {cliente?.tipologia_fatturazione === "Z" ? <DettaglioFatturazioneZucchetti setDati={setDati} dati={dati} cliente={cliente.codice_univoco} /> : cliente?.tipologia_fatturazione === "B" ? <DettaglioFatturazioneBpoint setDati={setDati} dati={dati} cliente={cliente.codice_univoco} /> : <DettaglioFatturazioneIIpay setDati={setDati} dati={dati} cliente={cliente.codice_univoco} />}
      </div>
      {cliente?.tipologia_fatturazione === "Z" ?
        <div className="col-span-4 h-[50vh]">
          <DataGridPro
density='compact'
            slots={{
              toolbar: CustomToolbarContattiEmail,
              loadingOverlay: LinearProgress,
              footer: CustomFooterPersonalized,
            }}
            //loading={loading}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            rows={listino}
            getRowId={(r) => r.codice}
          />
        </div> : ""}

    </div>
  </>
  )







}





const ModalPrestazione = ({ tipologia, tipologia_referente_esterno, cliente, dettaglio, listino, loadListino, prestazioniDisponibili, prestazione }) => {
  const [open, setOpen] = useState(false)
  const [importo, setImporto] = useState(0);
  const [dettaglioEmail, setDettaglioEmail] = useState({ referente: "", email_referente: "", tipologia: "" })
  const [prestazioneSel, setPrestazioneSel] = useState(null);
  const [elencoPrestazioni, setElencoPrestazioni] = useState([]);
  const handleChange = (e) => {

    let d = { ...dettaglioEmail };
    d[e.target.name] = e.target.value;
    setDettaglioEmail(d);


  }

  const handleChangePrestazione = (e) => {
    let presta = prestazioniDisponibili.filter(el => el.codice == e.target.value);
    setPrestazioneSel(presta[0])



  }

  const handleClickOpen = () => {
    if (tipologia === "inserimento") {

      let arrCodici = listino.map(el => el.codice);
      setElencoPrestazioni(prestazioniDisponibili.filter(el => arrCodici.indexOf(el.codice) === -1))
      setPrestazioneSel(null)
      setImporto(0)
    } else {
      setImporto(prestazione.importo)
      let presta = prestazioniDisponibili.filter(el => el.codice == prestazione.codice);
      setPrestazioneSel(presta[0])
      setElencoPrestazioni(prestazioniDisponibili)
    }

    setOpen(true);
  }



  const handleSave = async () => {


    try {


      if (tipologia === "inserimento") {
        let savePrestazione = await api.post("/prestazione_azienda/listino", { prestazione: { prestazione: prestazioneSel.codice, importo: importo, codice_univoco_azienda: cliente } })

        toast.success("salvato con successo")
      }

      if (tipologia === "modifica") {
        let savePrestazione = await api.put("/prestazione_azienda/listino", { prestazione: { prestazione: prestazioneSel.codice, importo: importo, codice_univoco_azienda: cliente, id: prestazione.id } })


        toast.success("salvato con successo")
      }


      if (tipologia === "elimina") {
        let savePayroll = await api.delete(`/referenti_aziende_esterno/${dettaglioEmail.ID}`,)

        toast.success("eliminato con successo")
      }

      loadListino();

    } catch {

      toast.error("errore durante il salvataggio")

    }


  }

  const loadPrestazioni = async () => {

    let res = await api.get("/prestazione_aziende")

  }

  return (


    <>
      {tipologia === "inserimento" ?
        <Button
          className="principal-button "
          text="Aggiungi"
          type="button"
          icon={<HiPlus className="button-icon" />}
          onClick={async () => {
            handleClickOpen()
          }}
        /> : tipologia === "modifica" ?
          <Button
            className=' hidden'
            id="editPrestazione"
            type='button'
            icon={<MdEdit className='button-icon' />}
            onClick={async () => {
              handleClickOpen()
            }}
          /> : <Button
            className=' hidden'
            id="deletePrestazione"
            type='button'
            icon={<MdEdit className='button-icon' />}
            onClick={async () => {
              handleClickOpen()
            }}
          />
      }
      <Modal isOpen={open} closeButton={() => setOpen(false)} headerText={tipologia + ` Prestazione`}
        leftButtons={<Button
          className="principal-button z-[110]"
          text={tipologia === "elimina" ? "Elimina" : "Salva"}
          type="button"
          icon={<HiOutlineCheck className="button-icon" />}
          onClick={async () => {
            handleSave()
          }}
        />}>
        <div className="grid grid-cols-2 gap-2">

          {tipologia === "elimina" ?
            <div className="col-span-2 ">
              Attenzione, sei sicuro di voler eliminare:
              <span className="font-bold">
                {" " + dettaglioEmail.referente + " - " + dettaglioEmail.email_referente + " - " + dettaglioEmail.tipologia_descrizione}
              </span>

            </div>

            : <>
              <div className="col-span-2 md:col-span-1">
                <Select
                  type="text"
                  label={"Prestazione"}
                  name="prestazione_sel"
                  onChange={(e) => handleChangePrestazione(e)}
                  value={prestazioneSel?.codice}
                  placeholder="Seleziona..."
                  options={elencoPrestazioni.map((el) => { return { id: el.codice, name: el.codice + " - " + el.descrizione } })}
                  disabled={tipologia === "modifica"}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <Input
                  type="text"
                  label={"Importo"}
                  name="importo"
                  onChange={(e) => setImporto(e.target.value)}
                  value={importo}
                  disabled={false}
                />
              </div>

            </>
          }
        </div>



      </Modal>
    </>)

}

const DettaglioFatturazioneZucchetti = ({ dati, setDati }) => {



  const handleChangeSelect = (e, name) => {

    let d = { ...dati };
    d[name] = parseInt(e.target.value);
    setDati(d);


  }


  const handleChange = (e) => {

    let d = { ...dati };
    d[e.target.name] = e.target.value;
    setDati(d);


  }

  const handleSave = async () => {


    try {
      const aziende = await api.post(`/dati_parcellazione_zucchetti`, { dati: dati });
      toast.success("SALVATAGGIO OK");

    } catch {
      toast.error("ERRORE NEL SALVATAGGIO");
    }



  }


  return (<div className="grid grid-cols-4 gap-2 border p-2 rounded-md">

    <div>
      <Select
        type="text"
        label={"Tipologia fatturazione"}
        name="tipologia_cliente"
        onChange={(e) => handleChangeSelect(e, "tipologia_cliente")}
        value={dati?.tipologia_cliente}
        placeholder="Seleziona..."
        options={[{ id: 0, name: "listino" }, { id: 1, name: "forfait" }]}

      />
    </div>
    {dati?.tipologia_cliente == 1 ? <>
      <div>
        <Input
          type="text"
          label={"Importo Forfait (186)"}
          name="importo_forfait"
          onChange={(e) => handleChange(e)}
          value={dati?.importo_forfait}
          disabled={false}
        />

      </div>
      <div>
        <Input
          type="text"
          label={"Cedolini Max compresi"}
          name="max_cedolini"
          onChange={(e) => handleChange(e)}
          value={dati?.max_cedolini}
          disabled={false}
        />

      </div>
    </> : ""
    }
    <div>
      <Input
        type="text"
        label={"Costo a cedolino " + (dati?.tipologia_cliente == 1 ? "(187)" : "(189)")}
        name="costo_extra"
        onChange={(e) => handleChange(e)}
        value={dati?.costo_extra}
        disabled={false}
      />

    </div>
    <div className="col-span-4 ">
      <Button
        className="principal-button z-[110]"
        text="Salva"
        type="button"
        icon={<HiOutlineCheck className="button-icon" />}
        onClick={async () => {
          handleSave()
        }}
      />
    </div>
  </div>
  )
}


const DettaglioFatturazioneIIpay = ({ dati, setDati }) => {
  const [listinoIIpay, setListinoIIpay] = useState([])

  const handleChangeSelect = (e, name) => {
    console.log(e.target.value, name)
    let d = { ...dati };
    d[name] = parseInt(e.target.value);
    setDati(d);


  }



  const handleChangeCheckbox = (e, name) => {

    let d = { ...dati };
    d[name] = e.target.checked ? 1 : 0;
    setDati(d);


  }
  const loadListino = async () => {
    try {
      const risp = await api.get(`/tipologia_listino_iipay`);
      setListinoIIpay(risp.data)

    } catch {
      toast.error("Errore durante il caricamento")
    }

  }


  useEffect(() => {

    loadListino();

  }, []);




  const handleSave = async () => {


    try {
      const aziende = await api.post(`/dati_parcellazione_iipay`, { dati: dati });
      toast.success("SALVATAGGIO OK");

    } catch {
      toast.error("ERRORE NEL SALVATAGGIO");
    }



  }
  return (<><div className="grid grid-cols-4 gap-2 justify-items-start border p-2 rounded-md">

    <div className="w-full">
      <Select
        type="text"
        label={"Listino"}
        name="tipologia_listino_iipay"
        onChange={(e) => handleChangeSelect(e, "tipologia_listino_iipay")}
        value={dati?.tipologia_listino_iipay}
        placeholder="Seleziona..."
        options={listinoIIpay}

      />
    </div>


    <div className="w-full">
      <Select
        type="text"
        label={"Modalità spedizione"}
        name="modalita_spedizione"
        onChange={(e) => handleChangeSelect(e, "modalita_spedizione")}
        value={dati?.modalita_spedizione}
        placeholder="Seleziona..."
        options={[{ id: 353, name: "Sped. cedolini mail" }, { id: 370, name: "Sped cedolini HR" }, { id: 354, name: "DOCUSIGN" }, { id: 420, name: "presenze web/workflow" }]}

      />
    </div>
    <div className="justify-self-start place-self-center">

      <CustomCheckbox
        label="F24"
        name="f24"
        checked={dati?.f24}
        // onClick={(e) => handleChangeCheckbox(e, "pubblicazione_documentale_dipendenti")}
        onClick={(e) => handleChangeCheckbox(e, "f24")}
        value={dati?.f24}
        disabled={false}
      />

    </div>
    <div className="col-span-4 ">
      <Button
        className="principal-button z-[110]"
        text="Salva"
        type="button"
        icon={<HiOutlineCheck className="button-icon" />}
        onClick={async () => {
          handleSave()
        }}
      />
    </div>

  </div></>)

}
const DettaglioFatturazioneBpoint = ({ dati, setDati }) => {
  const [listinoIIpay, setListinoIIpay] = useState([])



  const handleChangeCheckbox = (e, name) => {

    let d = { ...dati };
    d[name] = e.target.checked ? 1 : 0;
    setDati(d);


  }




  const handleSave = async () => {


    try {
      const aziende = await api.post(`/dati_parcellazione_bpoint`, { dati: dati });
      toast.success("SALVATAGGIO OK");

    } catch {
      toast.error("ERRORE NEL SALVATAGGIO");
    }



  }
  return (<><div className="grid grid-cols-4 gap-2 justify-items-start border p-2 rounded-md">

    <div className="col-span-2 md:col-span-1">

      <CustomCheckbox
        label="Fondi Assicurazione sanitaria"
        name="fondi_assicurazione_sanitaria"
        checked={dati?.fondi_assicurazione_sanitaria}
        onClick={(e) => handleChangeCheckbox(e, "fondi_assicurazione_sanitaria")}
        //onClick={(e) => filter(e, "assegnati")}
        value={dati?.fondi_assicurazione_sanitaria}
        disabled={false}
      />
    </div>
    <div className="col-span-2 md:col-span-1">
      <CustomCheckbox
        label="Cassa Edile"
        name="cassa_edile"
        checked={dati?.cassa_edile}
        onClick={(e) => handleChangeCheckbox(e, "cassa_edile")}
        //onClick={(e) => filter(e, "assegnati")}
        value={dati?.cassa_edile}
        disabled={false}
      />
    </div>
    <div className="col-span-2 md:col-span-1">
      <CustomCheckbox
        label="Pubblicazione documentale"
        name="pubblicazione_documentale"
        checked={dati?.pubblicazione_documentale}
        onClick={(e) => handleChangeCheckbox(e, "pubblicazione_documentale")}
        //onClick={(e) => filter(e, "assegnati")}
        value={dati?.pubblicazione_documentale}
        disabled={false}
      />
    </div>
    <div className="col-span-2 md:col-span-1">
      <CustomCheckbox
        label="Cliente Alfieri"
        name="alfieri"
        checked={dati?.alfieri}
        onClick={(e) => handleChangeCheckbox(e, "alfieri")}
        //onClick={(e) => filter(e, "assegnati")}
        value={dati?.alfieri}
        disabled={false}
      />
    </div>
    <div className="col-span-2 md:col-span-1">
      <CustomCheckbox
        label="Presenze manuali"
        name="presenze_manuali"
        checked={dati?.presenze_manuali}
        onClick={(e) => handleChangeCheckbox(e, "presenze_manuali")}
        //onClick={(e) => filter(e, "assegnati")}
        value={dati?.presenze_manuali}
        disabled={false}
      />
    </div>
    <div className="col-span-2 md:col-span-1">
      <CustomCheckbox
        label="Presenze hr portal"
        name="presenze_hr_portal"
        checked={dati?.presenze_hr_portal}
        onClick={(e) => handleChangeCheckbox(e, "presenze_hr_portal")}
        //onClick={(e) => filter(e, "assegnati")}
        value={dati?.presenze_hr_portal}
        disabled={false}
      />
    </div>
    <div className="col-span-2 md:col-span-1">
      <CustomCheckbox
        label="Pubblicazione documentale dipendenti"
        name="pubblicazione_documentale_dipendenti"
        checked={dati?.pubblicazione_documentale_dipendenti}
        onClick={(e) => handleChangeCheckbox(e, "pubblicazione_documentale_dipendenti")}
        //onClick={(e) => filter(e, "assegnati")}
        value={dati?.pubblicazione_documentale_dipendenti}
        disabled={false}
      />

    </div>
    <div className="col-span-4 ">
      <Button
        className="principal-button z-[110]"
        text="Salva"
        type="button"
        icon={<HiOutlineCheck className="button-icon" />}
        onClick={async () => {
          handleSave()
        }}
      />
    </div>

  </div></>)

}




const CopiaDaCliente = ({ cliente }) => {
  const [open, setOpen] = useState(false)
  const [aziende, setAziende] = useState([])
  const location = useLocation();



  const handleClick = () => {
    let az_temp = location.state.clienti.filter(el => el.tipologia_fatturazione === cliente.tipologia_fatturazione && el.codice_univoco != cliente.codice_univoco)
    setAziende(az_temp)
    setOpen(true)
  }




  return (<>

    <Button
      className="principal-button "
      text="Copia da altro cliente"
      type="button"
      icon={<HiClipboardCopy className="button-icon" />}
      onClick={handleClick}
    />
    <Modal isOpen={open} closeButton={() => setOpen(false)} headerText={"Copia da altro cliente"}
     leftButtons={<Button
      className="principal-button z-[110]"
      text={"Salva"}
      type="button"
      icon={<HiOutlineCheck className="button-icon" />}
      onClick={async () => {
       // handleSave()
      }}
    />}
    >
      <div className="flex flex-col justify-center items-center"> <div>Seleziona un cliente dal menù a tendina per copiare il listino e importarlo qui.</div>
        <div className="font-bold">ATTENZIONE, una volta confermato tutte le prestazioni già associate a questo cliente verranno rimosse per inserire quelle dell'altro cliente selezionato. </div>
      </div>
      <div>
        <Select
          type="text"
          label={"Prestazione"}
          name="aziendaSel"
          //onChange={(e) => handleChangePrestazione(e)}
          // value={prestazioneSel?.codice}
          placeholder="Seleziona..."
          options={aziende.map((el) => { return { id: el.codice_univoco, name: el.ragione_sociale } })}

        />
      </div>
    </Modal>
  </>
  )




}




